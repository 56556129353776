.internship-image{
    height: 200px !important;
    width: 100%;
}

.internship-image img{
    width: 100%;
    height: 100%;
}

/* .loader{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
} */
 .service-item{
    overflow: hidden !important;
 }

.heading-custom{
    margin-top: 100px;
}
.banner-section{
    border-radius: 20px;
    /* overflow: hidden; */
    width: 85%;
    height: 50vh !important;
    position: relative;
    margin: 0 auto;
    object-fit: fill !important;
}

.banner-section .loader{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 100%;
}

.banner-section img{
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    object-fit: cover !important;
    /* padding-top: 20px; */
}

.black-screen{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 50vh !important;
    background-color: #0000003d !important;
    color: white;

}

.black-screen-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    font-family: 'Inter';
}

.black-screen-content h1{
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 20px;
}

.black-screen-content p{
    max-width: 700px;
    font-size: 19px;
    text-align: center;
}

