/* .home-img-scroll {
  background-color: rgba(0, 0, 0, 0.586); 
  color: white; 
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin-top: 75px;
} */

/* .carousel-item {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
} */

/* .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
} */

/* .image-container .loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
} */



/* .text-container h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.text-container a {
  text-decoration: none;
  color: white;
  background-color: #007bff;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: bold;
  display: inline-block;
} */




.home-img-scroll {
  background-color: rgb(0, 0, 0) !important; 
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 3s ease-in-out;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
    filter: brightness(50%);
  transform-origin: left center
}

.image-container.zoom {
  transform: scale(1.2);
}



.text-container {
  position: absolute;
  color: white;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  max-width: 800px;
  margin-left: 7%;
  margin-top: 90px;
}
.text-container h1{
  font-size: 45px;
  font-weight: 800;
  font-family: "Inter" !important;
  margin-bottom: 30px;
  line-height: 70px;
}

@media (max-width: 768px) {
  .text-container h1 {
    font-size: 2rem; /* Decreased font size for mobile */
  }
  .home-img-scroll {
    height: 50vh;
    margin-top: 75px;
  }
  .text-container h1{
    font-size: 25px;
    font-weight: 600;
    line-height: 35px;
  }
}


