/* Overlay */
.overlay {
    position: fixed;
    inset: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
  }
  
  /* Modal Container */
  .modal-content {
    background: white;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    max-height: 97%;
    height: 100%;
    max-width: 70% !important;
    width: 100%;
    overflow-y: auto;
  }

  
  /* Modal Header */
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
   
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: 'Inter' !important;
  }
  
  /* Close Button */
  .close-btn {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .close-btn:hover {
    color: #000;
  }
  
  .icon {
    width: 20px;
    height: 20px;
  }
  
  /* Modal Body */
  .modal-body {
    overflow-y: auto;
    padding: 10px 20px;
  }

  @media screen and (max-width: 768px) {
    .modal-content{
      min-width: 90% !important;
      height: fit-content !important;
    }
  }