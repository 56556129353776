.section-custom{
    font-family: 'Poppins' !important;
}


.heading-custom{
    margin-top: 100px;
}
.banner-section{
    border-radius: 20px;
    overflow: hidden;
    width: 85%;
    height: 50vh;
    position: relative;
    margin: 0 auto;
}

.banner-section .loader{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 100%;
}

.banner-section img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.black-screen{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background-color: #0000003d !important;
    color: white;

}

.black-screen-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    font-family: 'Inter';
}

.black-screen-content h1{
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 20px;
}

.black-screen-content p{
    max-width: 700px;
    font-size: 19px;
    text-align: center;
}   