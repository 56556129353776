.admin-login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.604) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins" !important;
    z-index: 1000 !important; /* Ensure it’s on top of other content */
}

.admin-login-container {
    background: rgba(255, 255, 255, 0.089) !important; /* Slightly transparent background */
    backdrop-filter: blur(10px); /* Apply the blur effect */
    padding: 20px;
    border-radius: 8px;
    position: relative; /* Required for positioning the cancel icon */
    width: 100%;
    max-width: 400px;
    color: white; /* Text color for better contrast */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Optional: border to enhance glass effect */
}

.admin-login-container h2 {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
    margin-top: 25px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(255, 255, 255, 0.3); /* Lighter border */
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2); /* Slightly transparent input background */
    color: white; /* Input text color for better contrast */
}

/* .form-group input::placeholder,
.second-form-group::placeholder {
    color: white;
    opacity: 1; 
} */


.password-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px !important;
}
.password-div-input{
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(255, 255, 255, 0.3); /* Lighter border */
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2); /* Slightly transparent input background */
    color: white; /* Input text color for better contrast */
}

.hideShowBtn {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.hideShowBtn:hover {
    background-color: #0056b3;
}

.cancel-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: white; /* Icon color for contrast */
}

.cancel-icon:hover {
    color: #ccc; /* Slightly lighter color on hover */
}

.admin-login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it’s on top of other content */
}

.admin-login-container {
    background: rgba(255, 255, 255, 0.1); /* Slightly transparent background */
    backdrop-filter: blur(10px); /* Apply the blur effect */
    padding: 20px;
    border-radius: 8px;
    position: relative; /* Required for positioning the cancel icon */
    width: 100%;
    max-width: 400px;
    color: white; /* Text color for better contrast */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Optional: border to enhance glass effect */
}

.admin-login-container h2 {
    margin-bottom: 20px;
}

.loginBtn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
}

.loginBtn:hover {
    background-color: #0056b3;
}
