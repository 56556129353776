

.navbar-css {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  box-shadow: 0px 2px 10px #007bff4c;
}

.btn-1,.btn-2{
  font-family: 'Poppins';
  font-weight: 500 !important;
}

.admin-buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
}
.admin-buttons .content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
/* Ensure the body does not overlap with the fixed navbar
body {
  padding-top: 70px; 
} */
