.team h1,h2,h3{
    font-family: "Playfair Display", serif !important;
    font-weight: 500;
    
}
.card-data{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    height: 100%;
    padding: 0px 30px;
}

.card-data div{
    height: 100% !important;
    width: 280px;
}

.card-data-custom{
border: 2px solid #007bff;
overflow: hidden;
border-radius: 10px;
}

.card-custom-image-div{
height: 200px !important;
}
.card-custom-image-div img{
height: 230px !important;
}

.heading-custom{
    margin-top: 100px;
}
.banner-section{
    border-radius: 20px;
    overflow: hidden;
    width: 85%;
    height: 50vh !important;
    position: relative;
    margin: 0 auto;
    object-fit: contain !important;
}

.banner-section .loader{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 100%;
}

.banner-section img{
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover !important;
    /* padding-top: 10px; */
}

.black-screen{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 50vh !important;
    background-color: #0000005d;
    color: white;

}

.black-screen-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    font-family: 'Inter';
}

.black-screen-content h1{
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 20px;
}

.black-screen-content p{
    max-width: 700px;
    font-size: 19px;
    text-align: center;
}

/* body{
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8d8d8d;
} */
/* Custom styles to adjust the size on smaller screens */
@media (max-width: 767px) {
    .team-item {
        max-width: 100%; /* Ensure cards use full width on small screens */
        padding: 1rem; /* Adjust padding to make them smaller */
    }

    .team-img img {
        width: 100%; /* Ensure images are responsive and fit within the card */
        height: auto;
    }

    .team-content {
        padding: 0.5rem; /* Smaller padding in the card content */
    }

    .team-item h5 {
        font-size: 1rem; /* Smaller font size for team name */
    }

    .team-item p {
        font-size: 0.875rem; /* Smaller font size for the role */
    }
}

