/* .main-div{
    margin-top: 200px !important;
} */
.header-section{
    margin-top: 30px;
}
.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
}

.heading-custom{
    margin-top: 100px !important;
}
.banner-section{
    border-radius: 20px;
    /* overflow: hidden; */
    width: 85%;
    height: 50vh !important;
    position: relative;
    margin: 0 auto;
    object-fit: contain !important;
}

.banner-section .loader{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 100%;
}

.banner-section img{
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    object-fit: cover !important;
    /* padding-top: 20px; */
}

.black-screen{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 50vh !important;
    background-color: #0000003d !important;
    color: white;

}

.black-screen-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    font-family: 'Inter';
}

.black-screen-content h1{
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 20px;
}

.black-screen-content p{
    max-width: 700px;
    font-size: 19px;
    text-align: center;
}

.cards-main-div{
    padding: 30px 7%;

}
.card-img-top{
    height: 170px !important;
    object-fit: cover;
}

.card-minheading-one , .card-minheading-two{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 !important;
}

.card-minheading-one h6 , .card-minheading-two h6 {
    font-weight: 600;
}

.card-text-link{
    text-decoration: underline;
    color: #007bff;
    cursor: pointer;
}