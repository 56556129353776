.chat-logo {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 65px;
    height: 65px;
    cursor: pointer;
    border-radius: 50%;
    border: 5px solid rgb(74, 131, 218);
    z-index: 99999;
  }
  
  .dots-container {
    display: flex;
    align-items: center;
    gap: 10px;
    position: fixed;
    right: 8px;
    /* left: 969px; */
    bottom: 96px;
    z-index: 99999;
}
  .dot-large {
    margin-bottom: 5px;
    margin-right: -10px;
    width: 15px; /* Bigger dot */
    height: 15px;
    background-color: rgb(113, 180, 243);
    border-radius: 50%;
  }
  .dot-medium{
    margin-bottom: -13px;
    margin-right: -12px;
    width: 10px; /* Smaller dots */
    height: 10px;
    background-color: rgb(113, 180, 243);
    border-radius: 50%;
  }
  
  .dot-small {
    margin-top: 20px;
    margin-bottom: -10px;
    margin-right: 20px;
    width: 5px; /* Smaller dots */
    height: 5px;
    background-color: rgb(113, 180, 243);
    border-radius: 50%;
  }
  
  
  .chat-text {
    position: fixed;
    bottom: 36px;
    right: 60px;
    cursor: pointer;
    margin-bottom: 70px;
    border: 3px solid rgb(69, 69, 201);
    background-color: #fff;
    padding: 8px 10px;
    text-align: center;
    border-radius: 15px 15px 15px 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 200px;
    font-size: 11px;
    color: #333;
    z-index: 999999;
    font-family: 'Poppins';
}
  
  .chat-bubble::before {
    content: '';
    position: absolute;
    bottom: 10px;
    right: -10px; 
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid rgb(69, 69, 201);
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    /* width: 30px; */
    /* height: 30px; */
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    /* line-height: 30px; */
  }
  
  .chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 400px;
    height: 500px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: 'Poppins';
    display: flex;
    flex-direction: column;
    z-index: 10000;
  }
  
  .chat-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border-radius: 10px 10px 0 0;
    font-family: 'Poppins' !important;
  }

  .chat-header h3{
    font-family: 'Poppins' !important;
  }
  
  .header-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
  }
  
  .chat-body {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    position: relative; /* Ensure it's positioned */
    z-index: 9999; /* Increase as needed */
}

  .message-wrapper {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .message-wrapper.bot {
    justify-content: flex-start;
  }
  
  .message-wrapper.user {
    justify-content: flex-end;
  }
  
  .message {
    padding: 10px;
    border-radius: 5px;
    max-width: fit-content;
    word-wrap: break-word;
  }
  
  .message.bot {
    background-color: #d1e7ff;
    text-align: left;
  }
  
  .message.user {
    background-color: #d4edda;
    text-align: right;
  }
  
  .bot-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
  }
  
  .suggestion {
    background-color: #d1e7ff;
    color: #007bff;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    cursor: pointer;
    max-width: fit-content;
    word-wrap: break-word;
  }
  
  .suggestion:hover {
    background-color: #0056b3;
    color: white;
  }
  
  .chat-input {
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ccc;
    border-radius: 0 0 10px 10px;
    position: sticky;
    bottom: 0;
  }
  
  .chat-input input {
    width: 80%;
    padding: 10px;
    border: none;
    border-radius: 5px;
  }
  
  .chat-input button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .message {
    white-space: pre-line;
  }
  @media (max-width: 600px) {
    .chatbot-container {
        width: 90%;
        height: 80vh;
        bottom: 10px;
        right: 5%;
    }
    .chat-logo {
        width: 50px;
        height: 50px;
        bottom: 15px;
        right: 15px;
    }
    .chat-text {
        font-size: 10px;
        right: 50px;
    }
}
