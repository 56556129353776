body{
    font-family: 'Poppins';
}
.feature-item h5{
    font-size: 1.2rem;
}
.feature-item p{
    font-size: 1rem;
}

@media screen and (max-width: 1280px) {

    .feature-item{
        padding: 15px !important;
    }
    .feature-item h5{
        font-size: 1rem;
    }
    .feature-item p{
        font-size: .8rem;
    }
}