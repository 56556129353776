/* Overlay */
.overlay {
    position: fixed;
    inset: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
  }
  
  /* Modal Container */
  .modal-content {
    background: white;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    max-height: 80%;
    max-width: 40% !important;
    overflow-y: auto;
  }

  
  /* Modal Header */
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
   
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: 'Inter' !important;
  }
  
  /* Close Button */
  .close-btn {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .close-btn:hover {
    color: #000;
  }
  
  .icon {
    width: 20px;
    height: 20px;
  }
  
  /* Modal Body */
  .modal-body {
    overflow-y: auto;
    padding: 10px 20px;
    font-family: 'Poppins';
  }
  .one-div{
    display: flex;
    /* align-items: center; */
    gap: 20px;
  }
  .one-div h6{
    font-weight: 600;
    padding-top: 3px;
  }
  .name-tag{
    text-transform: capitalize;
  }
  .second-div{
    display: flex;
    align-items: center;
    width: 180px;
  }
  .skill-div{
    border: none;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
