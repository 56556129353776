.pagination-container {
    display: flex;
    gap: 4px;
    list-style: none;
    padding: 0;
  }
  
  .pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pagination-item .page-button {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 1.5px solid #124090;
    background-color: white;
    color: #124090;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .pagination-item .page-button:hover {
    background-color: #1240900a;
  }
  
  .pagination-item.active .page-button {
    background-color: #1240900a;
    border-color: #124090;
    color: #124090;
  }
  
  .dots {
    padding: 8px;
    color: #777;
  }
  