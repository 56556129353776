.main-div{
    margin-top: 70px !important;
}
.careers-banner-section{
    /* overflow: hidden; */
    width: 100%;
    height: 45vh !important;
    position: relative;
    margin: 0 auto;
    border-radius: 0px !important;
    object-fit: cover !important;
}

.careers-banner-section .loader{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 100%;
}

.careers-banner-section img{
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}

.carrers-black-screen{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 45vh !important;
    background-color: #00000080 !important;
    color: white;

}

.job-details-div{
    padding: 20px 4%;
    font-family: 'Poppins';
}

.desc-div h5{
font-weight: 600;
}

.desc-div p{
    color: #333333;
}


.resp-div h5{
font-weight: 600;
}

.resp-div p{
    color: #333333;
}
.resp-div-map{
    display: flex; 
    gap: 10px;
}
.resp-div-map .dot-div{
    margin-top: 5px;
    color: #007bff;
}
.applyNow-btn{
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.extra-div{
    border: 1px solid #666666;
    display: flex;
    justify-content: center;
    gap: 15px;
    padding-top: 10px;
    margin-top: 28px;
}
.extra-div p {
    color: #007bff;
    cursor: pointer;
}

/* for form */
.form-div{
    padding: 20px 4%;
    font-family: 'Poppins';  
}

.form-div-upload{
    border: 2px dashed #007bff;
    border-radius: 5px;
    padding: 20px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.upload-input{
    display: none;
}

.input-click{
    cursor: pointer;
}

.uploaded-view{
    display: flex;
    gap: 10px;
}
.uploaded-view .cancel-btn{
    cursor: pointer;
}

.form-btn{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
}
.skill-main-div{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.skill-div{
    border: 1px solid grey;
    border-radius: 5px;
    padding: 3px 4px;
    display: flex;
    gap: 10px;
}

.skill-div button{
    background: rgba(255, 0, 0, 0.829);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.skill-div button:hover{
    background: rgba(255, 0, 0, 0.966);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.admin-main-div{
    margin-top: 100px;
}
.career-data-main-div{
    width: 100% !important;
    padding: 20px 5%;
    
}
.career-data-main-div-header{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}
.details-div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.career-data-main-div-header img{
    height: 350px !important;
    width: 100%;
    border-radius: 20px;
}
.one-div{
    /* width: 100%; */
    display: flex;
    /* justify-content: space-between !important; */
    align-items: center;
    gap: 20px;
    /* margin-top: 10px; */
  }
  .one-div h6{
    font-weight: 600;
    padding-top: 3px;
  }
  .name-tag{
    text-transform: capitalize;
  }

  .second-div{
    display: flex;
    align-items: center;
    max-width: 150px !important;
  }
  .desription-tag{
    font-size: 12px;
  }

 @media screen and (max-width: 768px) {
    .career-name{
        font-size: 35px !important;
    }
    .career-data-main-div-header{
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
    }
 }